import { JsonRpcSigner, TransactionReceipt, ethers } from "ethers";
import stake from "../../assets/abi/Stake.json";
import { USE_MULTICALL, customSendTransaction, erc20Addresses, globalProvider, multicallGlobalProvider } from "../blockchain";

function getContract(isMulticall = false) {
  return new ethers.Contract(erc20Addresses.xGHST, stake.abi, isMulticall && USE_MULTICALL ? multicallGlobalProvider : globalProvider);
}

// Read

export async function earned(token: string, account: string): Promise<bigint> {
  return getContract().earned(token, account);
}

export async function totalSupply(): Promise<bigint> {
  return getContract().totalSupply();
}

export async function rewardsList(): Promise<string[]> {
  return getContract().rewardsList();
}

export async function rewardData(token: string): Promise<IRewardData> {
  return await getContract().rewardData(token).then(handleRewardDataResponse);
}

// Bulk

export function earned_bulk(tokenList: string[], account: string): Promise<bigint>[] {
  return tokenList.map((token) => getContract(true).earned(token, account));
}

export function rewardData_bulk(tokenList: string[]): Promise<IRewardData>[] {
  return tokenList.map((token) => getContract(true).rewardData(token).then(handleRewardDataResponse));
}

// Write

export async function getReward(signer: JsonRpcSigner): Promise<TransactionReceipt | any> {
  const unsignedTx = await getContract().getReward.populateTransaction();
  return await customSendTransaction(signer, unsignedTx);
}

export async function depositAll(signer: JsonRpcSigner): Promise<TransactionReceipt | any> {
  const unsignedTx = await getContract().depositAll.populateTransaction();
  return await customSendTransaction(signer, unsignedTx);
}

export async function deposit(signer: JsonRpcSigner, amount: bigint): Promise<TransactionReceipt | any> {
  const unsignedTx = await getContract().deposit.populateTransaction(amount);
  return await customSendTransaction(signer, unsignedTx);
}

export async function withdraw(signer: JsonRpcSigner, amount: bigint): Promise<TransactionReceipt | any> {
  const unsignedTx = await getContract().withdraw.populateTransaction(amount);
  return await customSendTransaction(signer, unsignedTx);
}

export async function withdrawAll(signer: JsonRpcSigner): Promise<TransactionReceipt | any> {
  const unsignedTx = await getContract().withdrawAll.populateTransaction();
  return await customSendTransaction(signer, unsignedTx);
}

// Interfaces

export interface IRewardData {
  rewardRate: bigint;
  periodFinish: bigint;
  lastUpdateTime: bigint;
  rewardPerTokenStored: bigint;
}

// Helpers

export function handleRewardDataResponse(_rewardData: any) {
  const _rewardList: IRewardData = {
    rewardRate: 0n,
    periodFinish: 0n,
    lastUpdateTime: 0n,
    rewardPerTokenStored: 0n,
  };

  _rewardData.forEach((rd: any, i: number) => {
    switch (i) {
      case 0:
        _rewardList.rewardRate = rd;
        break;
      case 1:
        _rewardList.periodFinish = rd;
        break;
      case 2:
        _rewardList.lastUpdateTime = rd;
        break;
      case 3:
        _rewardList.rewardPerTokenStored = rd;
        break;
    }
  });
  return _rewardList;
}
