import injectedModule from "@web3-onboard/injected-wallets";
import metamaskModule from "@web3-onboard/metamask";
import { init } from "@web3-onboard/react";
import walletConnectModule from "@web3-onboard/walletconnect";
import { fantomRPC } from "./helpers/blockchain";

const apiKey = "b18b42c1-1cb6-41fb-8cbe-e2babc0db180";

const injected = injectedModule();
const metamask = metamaskModule({
  options: {
    extensionOnly: false,
    i18nOptions: {
      enabled: true,
    },
    dappMetadata: {
      name: "fGhost",
    },
  },
});
const walletConnect = walletConnectModule({
  projectId: "e234c716cac3626ab7837729da5bdfa5",
  requiredChains: [250],
  dappUrl: "https://fghost.xyz",
});

export const initWeb3Onboard = init({
  theme: "dark",
  apiKey,
  connect: {
    autoConnectAllPreviousWallet: true,
  },
  wallets: [injected, metamask, walletConnect],
  chains: [
    {
      id: "0xfa",
      token: "FTM",
      label: "Fantom",
      rpcUrl: fantomRPC,
    },
  ],
});
