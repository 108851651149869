import { ethers } from "ethers";
import ghostLensABI from "../../assets/abi/ghostLens.json";
import { IPoolInfo } from "../../entities/interfaces";
import { erc20Addresses, globalProvider, multicallGlobalProvider, USE_MULTICALL } from "../blockchain";

function getContract(isMulticall = false, provider?: ethers.JsonRpcProvider) {
    return new ethers.Contract(erc20Addresses.ghostLens, ghostLensABI.abi, isMulticall && USE_MULTICALL ? multicallGlobalProvider : globalProvider);
}

// Read functions

export async function getPoolInfo(): Promise<IPoolInfo[]> {
    return getContract().getPoolInfo();
}

export async function tvl(poolAddress: string): Promise<bigint> {
    return getContract().tvl(poolAddress);
}

export async function whichStrat(poolAddress: string): Promise<string> {
    return "EQ";
    return getContract().whichStrat(poolAddress);
}

// Bulk

export function tvl_bulk(poolAddresses: string[], provider?: ethers.JsonRpcProvider): Promise<bigint>[] {
    return poolAddresses.map((poolAddress) => getContract(true, provider).tvl(poolAddress));
}

export function whichStrat_bulk(poolAddresses: string[], provider?: ethers.JsonRpcProvider): Promise<string>[] {
    return poolAddresses.map(pa => Promise.resolve("EQ"));
    return poolAddresses.map((poolAddress) => getContract(true, provider).whichStrat(poolAddress));
}