import { BsThreeDotsVertical } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiGitbook } from "react-icons/si";
import "./footer.css";

export default function FooterComponent() {
  function toggleSocials() {
    const socials = document.querySelector(".footer-icons-social") as HTMLElement;
    if (socials.className === "footer-icons-social hidden") {
      socials.classList.remove("hidden");

      const footerIcons = document.querySelector(".footer-icons") as HTMLElement;
      footerIcons.classList.add("complementary");
    } else {
      socials.classList.add("hidden");

      const footerIcons = document.querySelector(".footer-icons") as HTMLElement;
      footerIcons.classList.remove("complementary");
    }
  }

  return (
    <div className="footer">
      <div className="footer-icons" onClick={toggleSocials}>
        <BsThreeDotsVertical size={36} />
      </div>
      <div className="footer-icons-social hidden">
        <a target="_blank" href="https://google.com">
          <FaDiscord size={36} />
        </a>
        <a target="_blank" href="https://twitter.com/X">
          <FaXTwitter size={36} />
        </a>
        <a target="_blank" href="https://docs.fghost.xyz/">
          <SiGitbook size={36} />
        </a>
      </div>
      <div className="footer-icons-filler"></div>
    </div>
  );
}
