import { ethers } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";
import ghostChef from "../../assets/abi/ghostChef.json";
import { IPoolInfo, IUserInfo } from "../../entities/interfaces";
import { customSendTransaction, erc20Addresses, globalProvider, USE_MULTICALL } from "../blockchain";

function getContract(isMulticall = false, provider?: ethers.JsonRpcProvider) {
  const _provider = provider ? provider : globalProvider;
  const _multicallGlobalProvider = MulticallWrapper.wrap(_provider);
  return new ethers.Contract(erc20Addresses.ghostChef, ghostChef.abi, isMulticall && USE_MULTICALL ? _multicallGlobalProvider : _provider);
}

// Read

export async function readPoolList(): Promise<string[]> {
  return getContract().readPoolList();
}

export async function rSIXPerSecond(): Promise<bigint> {
  return getContract().rSIXPerSecond();
}

export async function poolInfo(poolId: number): Promise<IPoolInfo> {
  return getContract().poolInfo(poolId);
}

export async function userInfo(poolId: number, userAddress: string): Promise<IPoolInfo> {
  return getContract().userInfo(poolId, userAddress);
}

// Bulk

export function poolId_bulk(lpAddresses: string[], provider?: ethers.JsonRpcProvider): Promise<number>[] {
  return lpAddresses.map((lpAddress) => getContract(true, provider).poolId(lpAddress).then(((res) => Number(res))));
}

export function poolInfo_bulk(poolIds: number[], provider?: ethers.JsonRpcProvider): Promise<IPoolInfo>[] {
  return poolIds.map((poolId) => getContract(true, provider).poolInfo(poolId).then(((res) => ({
    lpToken: res.getValue("lpToken"),
    allocPoint: res.getValue("allocPoint"),
    lastRewardTime: res.getValue("lastRewardTime"),
    accFGHSTPerShare: res.getValue("accRSIXPerShare"),
    strategy: res.getValue("strategy")
  }))));
}

export function pendingRSIX_bulk(poolIds: number[], address: string, provider?: ethers.JsonRpcProvider): Promise<bigint>[] {
  return poolIds.map((poolId) => getContract(true, provider).pendingRSIX(poolId, address));
}

export function userInfo_bulk(poolIds: number[], userAddress: string, provider?: ethers.JsonRpcProvider): Promise<IUserInfo>[] {
  return poolIds.map((poolId) => getContract(true, provider).userInfo(poolId, userAddress).then(((res) => ({
    amount: res.getValue("amount"),
    rewardDebt: res.getValue("rewardDebt")
  }))));
}

// Write

export async function deposit(signer: ethers.JsonRpcSigner, lpAddress: number, amount: bigint): Promise<any> {
  const unsignedTx = await getContract().deposit.populateTransaction(lpAddress, amount);
  return await customSendTransaction(signer, unsignedTx);
};

export async function withdraw(signer: ethers.JsonRpcSigner, lpAddress: number, amount: bigint): Promise<any> {
  const unsignedTx = await getContract().withdraw.populateTransaction(lpAddress, amount);
  return await customSendTransaction(signer, unsignedTx);
};

export async function harvestAll(signer: ethers.JsonRpcSigner): Promise<any> {
  const unsignedTx = await getContract().harvestAll.populateTransaction();
  return await customSendTransaction(signer, unsignedTx);
};
