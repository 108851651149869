import { Outlet } from "react-router-dom";
import FooterComponent from "../components/footer";
import HeaderComponent from "../components/header";

export default function LayoutPage() {
  return (
    <div>
      <HeaderComponent />
      <Outlet />
      <FooterComponent />
    </div>
  );
}
