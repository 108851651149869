import { ethers } from "ethers";
import crematoriumLP from "../../assets/abi/crematoriumLP.json";
import { globalProvider, multicallGlobalProvider, USE_MULTICALL } from "../blockchain";
import * as vaultControllerContract from "./vault-beethoven.contract";

// This is a virtual contract, is can take several designs

function getContract(lpAddress: string, isMulticall = false) {
  return new ethers.Contract(lpAddress, crematoriumLP.abi, isMulticall && USE_MULTICALL ? multicallGlobalProvider : globalProvider);
}

// Read

export async function getSymbol(lpAddress: string): Promise<string> {
  return getContract(lpAddress).symbol();
}

export async function getName(lpAddress: string): Promise<string> {
  return getContract(lpAddress).name();
}

export async function getTokens(lpAddress: string): Promise<string[]> {
  // If the LP is from EQUALIZER
  return getContract(lpAddress).tokens().catch(async () => {
    // If the LP is from BEETS
    const poolId = await getContract(lpAddress).getPoolId().catch(() => "");
    if (poolId) {
      const beetsPoolTokens = await vaultControllerContract.getPoolTokens(poolId);
      return beetsPoolTokens.tokens;
    } else {
      return [];
    }
  });
}
