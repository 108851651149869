import "@gasbot/widget/style.css";
import { useConnectWallet } from "@web3-onboard/react";
import { JsonRpcSigner, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { CiWallet } from "react-icons/ci";
import { TbPigMoney } from "react-icons/tb";
import { VscThreeBars } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { fantomRPC } from "../helpers/blockchain";
import { truncateEthAddress } from "../helpers/utils";
import "./header.css";

export default function HeaderComponent() {
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { switcher, themes, currentTheme } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [fBUXPrice, setfBUXPrice] = useState<number>(0);
  const [path, setPath] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (window.ethereum) {
      const init = async () => {};

      init();
    }

    function setCloseMethods() {
      document.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          event.preventDefault();
          setIsMenuOpen(false);
        }
      });
    }

    return setCloseMethods();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("fGHSTTokenPrice") && localStorage.getItem("fGHSTTokenPrice") !== "undefined") {
      setfBUXPrice(JSON.parse(localStorage.getItem("fGHSTTokenPrice")!));
    }
  }, [localStorage.getItem("fGHSTTokenPrice")]);

  useEffect(() => {
    changeOrAddChain();

    async function changeOrAddChain() {
      if (wallet) {
        const firstSigner = !!!signer;
        const provider = new ethers.BrowserProvider(wallet.provider);
        const newSigner = await provider?.getSigner();
        setSigner(newSigner);

        if (window.ethereum && firstSigner) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0xfa" }],
            });
          } catch (err) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0xfa",
                    rpcUrls: [fantomRPC],
                    chainName: "Fantom",
                    nativeCurrency: {
                      name: "FTM",
                      symbol: "FTM",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://ftmscan.com/"],
                  },
                ],
              });
            } catch (err) {}
          }
        }
      }
    }
  }, [wallet]);

  useEffect(() => {
    setIsDarkMode(currentTheme === themes.dark);
  }, [currentTheme]);

  useEffect(() => {
    const url = new URL(window.location.href);
    setPath(url.pathname);
  }, [window.location.href]);

  function toggleDarkMode() {
    switcher({ theme: isDarkMode ? themes.light : themes.dark });
    localStorage.setItem("theme", JSON.stringify(!isDarkMode));
  }

  function getNavBarLink(navLink: string) {
    return (
      <div className={"text-navbar-link" + (path === "/" + navLink.toLowerCase() ? " selected" : "")}>
        <button className="navbar-menu" onClick={() => setIsMenuOpen(true)}>
          <VscThreeBars />
          <Link to={"/" + navLink.toLowerCase()}>{navLink}</Link>
        </button>
        <Link className="navbar-links" to={"/" + navLink.toLowerCase()}>
          {navLink}
        </Link>
      </div>
    );
  }

  return (
    <div className="header">
      <div className="title">
        <img src="fghost-logo.png" alt="logo" className="logo-pfp" />
      </div>
      <div className="navbar">
        <nav>{getNavBarLink("Furnace")}</nav>
        <nav>{getNavBarLink("Crematorium")}</nav>
      </div>
      <div className="connector-wrapper">
        <div className="fghost-price">
          <img alt="fGhost" src={`./tokens/fantom/0x8b7007e1d02d8387b7b4bc8c6172598780ae59b2.png`} />${fBUXPrice.toFixed(4)}
        </div>
        <button
          className="connector text-wallet"
          disabled={connecting}
          onClick={() => {
            wallet ? disconnect(wallet) : connect();
          }}
        >
          <CiWallet />
          {connecting ? "Connecting" : wallet ? truncateEthAddress(wallet.accounts[0].address) : "Connect Wallet"}
        </button>
        <img className="ftm" alt="FTM" src="ftm.svg" />
      </div>
      <div className={"navbar-menu-mobile" + (isMenuOpen ? " show" : "")}>
        <Link className="navbar-links" to={"/furnace"} onClick={() => setIsMenuOpen(false)}>
          <TbPigMoney />
          Furnace
        </Link>
        <Link className="navbar-links" to={"/crematorium"} onClick={() => setIsMenuOpen(false)}>
          <TbPigMoney />
          Furnace
        </Link>
      </div>
      {isMenuOpen && <div className="navbar-cover" onClick={() => setIsMenuOpen(false)}></div>}
    </div>
  );
}
