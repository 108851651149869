import { useState } from "react";
import "./disclaimer.css";

export default function DisclaimerComponent({ closeFn }: { closeFn: () => void }) {
  const [acceptedCheckbox, setAcceptedCheckbox] = useState<boolean>(false);

  function onAgree() {
    const object = { value: "true", timestamp: new Date().getTime() };
    localStorage.setItem("disclaimer", JSON.stringify(object));
    closeFn();
  }

  function changeAccept() {
    setAcceptedCheckbox(!acceptedCheckbox);
  }

  return (
    <div className="disclaimer-modal">
      <div className="disclaimer-modal-content white-card">
        <div className="disclaimer-header">Welcome to FLaming Ghost Finance</div>
        <div className="disclaimer-body">
          <span>fGhost.</span>
          <span>
            By using our site, you're accepting our{" "}
            <a href="www.google.com" target="_blank">
              Legal Disclaimer
            </a>
          </span>

          <div className="disclaimer-checkbox">
            <input type="checkbox" onClick={() => changeAccept()} checked={acceptedCheckbox}></input>I have read and agree to the Legal
            Disclaimer.
          </div>
        </div>
        <div className="disclaimer-buttons">
          <button className="regular-btn" onClick={() => onAgree()} disabled={!acceptedCheckbox}>
            Agree
          </button>
        </div>
      </div>
      <div className="disclaimer-modal-background"></div>
    </div>
  );
}
