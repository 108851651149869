import Decimal from "decimal.js";
import { ethers } from "ethers";
import { TokenPrice } from "../entities/beets-graphql.entities";
import { PoolIndividualTokens } from "../entities/interfaces";
import { erc20Addresses } from "./blockchain";
import * as vaultControllerContract from "./contracts/vault-beethoven.contract";

export const truncateEthAddress = (address: string) => {
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const stringNumberToStringFixed = (balance: string, decimals = 2) => {
  if (!balance || balance === "0") return "0" + ".".padEnd(decimals + 1, "0");

  const parts = balance.split(".");
  if (parts.length !== 2) {
    return balance;
  }
  if (decimals === 0) {
    return parts[0];
  }
  return parts[0] + "." + parts[1].padEnd(decimals, "0").substring(0, decimals);
};

export const bigIntToString = (balance: bigint, decimals = 18) => {
  if (!balance) return "0";

  return ethers.formatUnits(balance.toString(), decimals);
};

export const displayFTMToContractFTM = (balance: bigint): bigint => {
  if (!balance) return 0n;
  return balance * 10n ** 18n;
};

export const bigintToFixed = (balance: bigint, contractDecimals = 18, displayDecimals = 2) => {
  return stringNumberToStringFixed(bigIntToString(balance, contractDecimals), displayDecimals);
};

export const bigintToRoundFixed = (balance: bigint, contractDecimals = 18, displayDecimals = 2) => {
  return parseFloat(bigIntToString(balance, contractDecimals)).toFixed(displayDecimals);
};

export function stringNumberToFixed(number: string, decimals = 2) {
  return Number(Number(stringNumberToStringFixed(number, 14)).toFixed(decimals));
}

export function stringToFormattedDecimal(amount: string) {
  const amountBI = ethers.parseEther(amount);
  return bigintToFormattedDecimal(amountBI);
}

export function bigintToFormattedDecimal(amount: bigint) {
  const amountString = ethers.formatEther(amount.toString());
  const amountDecimal = new Decimal(amountString);
  return amountDecimal;
}

export function getCurrentTimestamp(): bigint {
  return BigInt(Math.floor(Date.now() / 1000));
}

export function getTokenPricesByAddresses(addresses: string[], tokenPricesList: TokenPrice[], sharePrice = 0): TokenPrice[] {
  const tokenPrices: TokenPrice[] = [];
  addresses.forEach((address) => {
    const tokenPrice = tokenPricesList.find((tp) => tp.address.toLowerCase() === address.toLowerCase());
    if (tokenPrice) {
      tokenPrices.push(tokenPrice);
    } else if (address.toLowerCase() === erc20Addresses.fGHST80USDCe20.toLowerCase()) {
      tokenPrices.push({ address, price: sharePrice, __typename: "" });
    }
  });
  return tokenPrices;
}

export function lendingRateToFixedAPY(lendingRatePerBlock: bigint, decimals = 2) {
  if (!lendingRatePerBlock) return "0";

  const lendingRateDecimal = new Decimal(lendingRatePerBlock.toString());
  const mantissa = (1n * 10n) ** 18n;
  const blocksPerDay = new Decimal(31536000 / 365);
  const daysPerYear = new Decimal(365);
  const rate = lendingRateDecimal.div(new Decimal(mantissa.toString())).mul(blocksPerDay).plus(1).pow(daysPerYear).sub(1).mul(100);
  return parseFloat(rate.toFixed(decimals)).toFixed(decimals);
}

export function getDisplayDecimals(underlyingAddress: string) {
  switch (underlyingAddress.toLowerCase()) {
    // lzWETH
    case "0x695921034f0387eac4e11620ee91b1b15a6a09fe":
      return 4;
    // lzWBTC
    case "0xf1648c50d2863f780c57849d812b4b7686031a3d":
      return 6;
  }
  return 2;
}

export function formatCash(n: number) {
  if (n < 1e3) return n.toString();
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(3) + "T";
  else return n.toString();
}

export function formatSeconds(seconds: number, short: boolean = false) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + "d, " : "";
  var hDisplay = h > 0 ? h + "h, " : "";
  if (short && h > 0) {
    return ("~" + dDisplay + hDisplay).slice(0, -2);
  }
  var mDisplay = m > 0 ? m + "m, " : "";
  var sDisplay = s > 0 ? s + "s" : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function formatSecondsToDays(seconds: number) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));

  var dDisplay = d > 0 ? d + " days" : "";
  return dDisplay;
}

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPoolIndividualTokens(
  poolOwnedAmount: bigint,
  poolTokensTotalSupply: bigint,
  poolTokens: vaultControllerContract.IVaultBeethovenGetPoolTokens
): PoolIndividualTokens {
  if (!poolTokensTotalSupply || !poolTokens) {
    return { fBUX: 0n, USDCe: 0n };
  }
  const ownedPoolPercentageDecimal = new Decimal(poolOwnedAmount.toString()).mul(100).div(poolTokensTotalSupply.toString());
  const ownedPoolfBUXAmountDecimal = ownedPoolPercentageDecimal.mul(poolTokens.balances[0].toString()).div(100);
  const ownedPoolUSDCAmountDecimal = ownedPoolPercentageDecimal.mul(poolTokens.balances[1].toString()).div(100);
  const ownedPoolfBUXAmount = BigInt(ownedPoolfBUXAmountDecimal.toFixed(0));
  const ownedPoolUSDCAmount = BigInt(ownedPoolUSDCAmountDecimal.toFixed(0));
  return {
    fBUX: ownedPoolfBUXAmount,
    USDCe: ownedPoolUSDCAmount,
  };
}