import { PoolIndividualTokens } from "./interfaces";

export const enum StakingAction {
    Stake = "Stake",
    Withdraw = "Withdraw",
}

export const emptyIndividualPoolTokens: PoolIndividualTokens = {
    fBUX: 0n,
    USDCe: 0n
};