import { ethers } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";
import erc20 from "../../assets/abi/ERC20.json";
import { customSendTransaction, globalProvider, USE_MULTICALL } from "../blockchain";

function getContract(erc20Address: string, isMulticall = false, provider?: ethers.JsonRpcProvider) {
  const _provider = provider ? provider : globalProvider;
  const _multicallGlobalProvider = MulticallWrapper.wrap(_provider);
  return new ethers.Contract(erc20Address, erc20.abi, isMulticall && USE_MULTICALL ? _multicallGlobalProvider : _provider);
}

// Read

export async function getERC20_Symbol(erc20Address: string): Promise<string> {
  return getContract(erc20Address).symbol();
}

export async function getERC20_Name(erc20Address: string): Promise<string> {
  return getContract(erc20Address).name();
}

export async function getERC20_Decimals(erc20Address: string): Promise<bigint> {
  return getContract(erc20Address).decimals();
}

export async function getERC20Balance(address: string, erc20Address: string) {
  return getContract(erc20Address).balanceOf(address);
}

export async function allowance(address: string, spender: string, erc20Address: string) {
  return getContract(erc20Address).allowance(address, spender);
}

// Bulk

export function allowance_bulk(address: string, spender: string, erc20Addresses: string[], provider?: ethers.JsonRpcProvider): Promise<bigint>[] {
  return erc20Addresses.map((erc20Address) => getContract(erc20Address, true, provider).allowance(address, spender));
}

export function balanceOf_bulk(address: string, erc20Addresses: string[], provider?: ethers.JsonRpcProvider): Promise<bigint>[] {
  return erc20Addresses.map((erc20Address) => getContract(erc20Address, true, provider).balanceOf(address));
}

export function symbol_bulk(erc20Addresses: string[]): Promise<string>[] {
  return erc20Addresses.map((erc20Address) => getContract(erc20Address, true).symbol());
}

// Write

export async function approve(signer: ethers.JsonRpcSigner, token: string, spender: string, amount: bigint): Promise<any> {
  const unsignedTx = await getContract(token).approve.populateTransaction(spender, amount);
  return await customSendTransaction(signer, unsignedTx);
};
