import { getERC20_Decimals, getERC20_Symbol } from "./contracts/erc20.contract";

export interface IToken {
    address: string;
    decimals: number;
    symbol: string;
}

function allUsedTokensNeeded(): string[] {
    return [
        "0x2F733095B80A04b38b0D10cC884524a3d09b836a"
    ];
}


export const getAllTokensInfo = async (): Promise<IToken[] | undefined> => {
    const allUsedTokens = allUsedTokensNeeded();

    if (allUsedTokens.length > 0) {

        const groupedPromises: Promise<string | bigint>[] = [];
        const allMarketsData: IToken[] = [];

        for (let i = 0; i < allUsedTokens.length; i++) {
            const tokenAddress = allUsedTokens[i];

            groupedPromises.push(getERC20_Symbol(tokenAddress));
            groupedPromises.push(getERC20_Decimals(tokenAddress));
        }

        const groupedResults = await Promise.all(groupedPromises);

        // Process second group results
        for (let i = 0; i < allUsedTokens.length; i++) {
            const market = allUsedTokens[i].toLowerCase();
            allMarketsData.push({
                address: market.toLowerCase(),
                symbol: groupedResults[i * 2] as string,
                decimals: Number(groupedResults[i * 2 + 1]),
            });
        }
        return allMarketsData;
    }

    return undefined;
};
