import { RiseLoader } from "react-spinners";
import "./modal.css";

export default function LoadingButtonComponent({
  onClick,
  content,
  cssClass,
  isLoading,
  disabled,
  id,
}: {
  onClick: () => void;
  content: any;
  cssClass?: string;
  isLoading: boolean;
  disabled?: boolean;
  id?: string;
}) {
  return (
    <button className={cssClass ? cssClass : "regular-btn"} onClick={onClick} disabled={disabled} id={id}>
      {!isLoading ? <>{content}</> : <RiseLoader color="#181f1b" size={15} loading={isLoading} />}
    </button>
  );
}
