import { useWallets } from "@web3-onboard/react";
import { Decimal } from "decimal.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import { HashLoader } from "react-spinners";
import { Tooltip } from "react-tooltip";
import LoadingButtonComponent from "../components/loading-button";
import { TokenPrice } from "../entities/beets-graphql.entities";
import { emptyIndividualPoolTokens, StakingAction } from "../entities/constants";
import { IClaimableRewards, PoolIndividualTokens } from "../entities/interfaces";
import { getBeetsPool, getBeetsTokenPrices } from "../helpers/beets-graphql";
import { ALL_TOKENS_INFO, erc20Addresses } from "../helpers/blockchain";
import * as erc20Contract from "../helpers/contracts/erc20.contract";
import { approve } from "../helpers/contracts/erc20.contract";
import * as stakeContract from "../helpers/contracts/stake.contract";
import * as vaultControllerContract from "../helpers/contracts/vault-beethoven.contract";
import {
  bigintToFixed,
  bigintToFormattedDecimal,
  getPoolIndividualTokens,
  getTokenPricesByAddresses,
  stringNumberToFixed,
  stringNumberToStringFixed,
  stringToFormattedDecimal,
} from "../helpers/utils";
import "./furnace.css";

export default function FurnacePage() {
  const [stakingAction, setStakingAction] = useState<StakingAction>(StakingAction.Stake);
  const [connectedWallet, setConnectedWallet] = useState<any>(null);
  const [browserProvider, setBrowserProvider] = useState<any>(null);

  // Load at init
  const [rewardTokensList, setRewardTokensList] = useState<string[]>([]);
  const [tokenPrices, setTokenPrices] = useState<TokenPrice[]>([]);
  const [sharePrice, setSharePrice] = useState<number>(0);
  const [fuBUXTotalSupply, setfuBUXTotalSupply] = useState<bigint>(0n);
  const [totalTVLDisplay, setTotalTVLDisplay] = useState<string>("0");

  // Load after init
  const [stakingAPR, setStakingAPR] = useState<number>(0);
  const [feesAPR, setFeesAPR] = useState<number>(0);
  const [stakeRewardsAprs, setStakeRewardsAprs] = useState<{ address: string; apr: number }[]>([]);
  const [stakeClaimableRewards, setStakeClaimableRewards] = useState<IClaimableRewards[]>([]);

  // Load on connected Wallet
  const [allowance, setAllowance] = useState<bigint>(0n);
  const [fBUX80USDCe20Balance, setfBUX80USDCe20Balance] = useState<bigint>(0n);
  const [fuBUXBalance, setfuBUXBalance] = useState<bigint>(0n);
  const [poolTokens, setPoolTokens] = useState<vaultControllerContract.IVaultBeethovenGetPoolTokens>();
  const [poolTokensTotalSupply, setPoolTokensTotalSupply] = useState<bigint>(0n);

  // Update control
  const [refreshAllowance, setRefreshAllowance] = useState<boolean>(false);
  const [refreshBalances, setRefreshBalances] = useState<boolean>();
  const [claimRewardsLoading, setClaimRewardsLoading] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [stakeLoading, setStakeLoading] = useState<boolean>(false);
  const [refreshRewards, setRefreshRewards] = useState<boolean>(false);
  const [refreshInit, setRefreshInit] = useState<boolean>(false);

  // Fields
  const [commitAmountBI, setCommitAmountBI] = useState<bigint>(0n);
  const [commitWithdrawAmountBI, setCommitWithdrawAmountBI] = useState<bigint>(0n);
  const [poolStakedTokensIndividual, setPoolStakedTokensIndividual] = useState<PoolIndividualTokens>(emptyIndividualPoolTokens);
  const [poolUnstakedTokensIndividual, setPoolUnstakedTokensIndividual] = useState<PoolIndividualTokens>(emptyIndividualPoolTokens);
  const [holdingsStakedfuBUX, setHoldingsStakedfuBUX] = useState<string>("0");
  const [holdingsUnstaked80fBUX20USDCe, setHoldingsUnstaked80fBUX20USDCe] = useState<string>("0");

  const wallets = useWallets();
  const beetsPoolAddress = "https://beets.fi/pool/" + erc20Addresses.pool_Beethoven;

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshInit((_refreshInit) => !_refreshInit);
    }, 10000);

    const interval2 = setInterval(() => {
      setRefreshRewards((_refreshRewards) => !_refreshRewards);
      setRefreshBalances((_refreshBalances) => !_refreshBalances);
    }, 5000);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  useEffect(() => {
    setStakingAPR(feesAPR + stakeRewardsAprs.reduce((sum, stakeRewards) => sum + stakeRewards.apr, 0));
  }, [feesAPR, stakeRewardsAprs]);

  useEffect(() => {
    if (wallets.length) {
      setBrowserProvider(new ethers.BrowserProvider(wallets[0].provider));
      setConnectedWallet(wallets[0].accounts[0]);
    } else {
      setConnectedWallet(null);
    }
  }, [wallets]);

  useEffect(() => {
    updateRewardTokensList();
    updateSharePrice();
    updateTokenPrices();
    updateTotalSupply();

    async function updateRewardTokensList() {
      const _rewardTokensList = await stakeContract.rewardsList();
      setRewardTokensList(_rewardTokensList);
    }

    async function updateSharePrice() {
      const poolResponse = await getBeetsPool();
      const poolData = poolResponse.data;
      const totalShares = poolData.pool.dynamicData.totalShares;
      const totalLiquidity = poolData.pool.dynamicData.totalLiquidity;
      const totalSharesDecimal = stringToFormattedDecimal(totalShares);
      const totalLiquidityDecimal = stringToFormattedDecimal(totalLiquidity);
      const sharePrice = totalLiquidityDecimal.dividedBy(totalSharesDecimal);
      setSharePrice(Number(sharePrice.toFixed(6)));

      const swapAprDecimal = stringToFormattedDecimal(poolData.pool.dynamicData.apr.swapApr);
      const _feesAPR = swapAprDecimal.mul(100);
      setFeesAPR(Number(_feesAPR.toFixed(2)));
    }

    async function updateTokenPrices() {
      const tokenPricesResponse = await getBeetsTokenPrices();
      const tokenPricesData = tokenPricesResponse.data;
      setTokenPrices(tokenPricesData.tokenPrices);
    }

    async function updateTotalSupply() {
      const fuBUXTotalSupply = await stakeContract.totalSupply();
      setfuBUXTotalSupply(fuBUXTotalSupply);
    }
  }, [refreshInit]);

  useEffect(() => {
    if (connectedWallet && tokenPrices.length && rewardTokensList.length) {
      updateClaimableRewards();
    }

    async function updateClaimableRewards() {
      const rewardTokenPrices = getTokenPricesByAddresses(rewardTokensList, tokenPrices);

      const [earnedResponses, tokenSymbols] = await Promise.all([
        Promise.all(stakeContract.earned_bulk(rewardTokensList, connectedWallet.address)),
        Promise.all(erc20Contract.symbol_bulk(rewardTokensList)),
      ]);

      let _stakeClaimableRewards: { address: string; claimable: bigint; holdings: string; symbol: string }[] = [];
      for (let i = 0; i < rewardTokensList.length; i++) {
        const rewardToken = rewardTokensList[i];
        const earnedAmount = earnedResponses[i];
        const earnedAmountDecimal = new Decimal(earnedAmount.toString());
        const thisRewardTokenPrice = rewardTokenPrices.find((tp) => tp.address.toLowerCase() === rewardToken.toLowerCase());

        if (thisRewardTokenPrice) {
          const _rewardHoldings = new Decimal(thisRewardTokenPrice.price).mul(earnedAmountDecimal).toFixed(0);

          const rewardApr = {
            address: rewardToken.toLowerCase(),
            claimable: earnedAmount,
            holdings: ethers.formatEther(_rewardHoldings),
            symbol: tokenSymbols[i],
          };
          _stakeClaimableRewards = [..._stakeClaimableRewards, rewardApr];
        }
      }
      var sorted = _stakeClaimableRewards.sort(({ holdings: a }, { holdings: b }) => Number(b) - Number(a));
      setStakeClaimableRewards(sorted);
    }
  }, [connectedWallet, tokenPrices.length, rewardTokensList.length, refreshRewards]);

  useEffect(() => {
    if (fuBUXTotalSupply > 0n && sharePrice > 0) {
      const fuBUXTotalSupplyDecimal = bigintToFormattedDecimal(fuBUXTotalSupply);
      const totalTVL = fuBUXTotalSupplyDecimal.mul(sharePrice);
      const _totalTVLDisplay = totalTVL.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setTotalTVLDisplay(_totalTVLDisplay);
    }
  }, [fuBUXTotalSupply, sharePrice]);

  useEffect(() => {
    if (rewardTokensList.length && tokenPrices.length && sharePrice > 0 && fuBUXTotalSupply > 0) {
      updateRewardsAPR();
    }

    async function updateRewardsAPR() {
      const rewardTokenPrices = getTokenPricesByAddresses(rewardTokensList, tokenPrices);
      const rewardDataResponses = await Promise.all(stakeContract.rewardData_bulk(rewardTokensList));

      let _stakeRewardsAprs: { address: string; apr: number }[] = [];
      for (let i = 0; i < rewardTokensList.length; i++) {
        const rewardToken = rewardTokensList[i];
        const fuBUXTotalSupplyDecimal = bigintToFormattedDecimal(fuBUXTotalSupply);
        const thisRewardTokenPrice = rewardTokenPrices.find((tp) => tp.address.toLowerCase() === rewardToken.toLowerCase())?.price;

        if (thisRewardTokenPrice) {
          const rewardData = rewardDataResponses[i];
          const rewardRateDecimal = new Decimal(rewardData.rewardRate.toString());
          const thisRewardRatePricePerToken = new Decimal(rewardRateDecimal.div(fuBUXTotalSupplyDecimal).toFixed(2)).mul(
            thisRewardTokenPrice,
          );

          const aprPerTick = thisRewardRatePricePerToken.mul(100).div(sharePrice).trunc();
          const apr = aprPerTick.mul(31536000);
          const tokenDecimals = ALL_TOKENS_INFO.find((t) => t.address.toLowerCase() === rewardToken.toLowerCase())?.decimals || 18;
          const aprDisplay = ethers.formatUnits(apr.toFixed(0), tokenDecimals);

          const rewardApr = {
            address: rewardToken.toLowerCase(),
            apr: stringNumberToFixed(aprDisplay),
          };
          _stakeRewardsAprs = [..._stakeRewardsAprs, rewardApr];
        }
      }
      var sorted = _stakeRewardsAprs.sort(({ apr: a }, { apr: b }) => b - a);
      setStakeRewardsAprs(sorted);
    }
  }, [rewardTokensList.length, tokenPrices.length, sharePrice, fuBUXTotalSupply]);

  useEffect(() => {
    if (connectedWallet) {
      fetchAccountData();

      async function fetchAccountData() {
        const [fuBUXBalanceResponse, fBUX80USDCe20BalanceResponse, poolTokensResponse] = await Promise.all([
          erc20Contract.getERC20Balance(connectedWallet.address, erc20Addresses.xGHST),
          erc20Contract.getERC20Balance(connectedWallet.address, erc20Addresses.fGHST80USDCe20),
          vaultControllerContract.getPoolTokens(erc20Addresses.pool_Beethoven),
        ]);

        setfuBUXBalance(fuBUXBalanceResponse);
        setfBUX80USDCe20Balance(fBUX80USDCe20BalanceResponse);
        setPoolTokens(poolTokensResponse);
      }
    } else {
      clearAccountFields();
    }
  }, [connectedWallet, refreshBalances]);

  useEffect(() => {
    if (connectedWallet && sharePrice > 0 && fuBUXBalance > 0n) {
      const fuBUXBalanceDecimal = bigintToFormattedDecimal(fuBUXBalance);
      const _holdingfuBUX = fuBUXBalanceDecimal.mul(sharePrice);
      const _holdingfuBUXString = _holdingfuBUX.toFixed();
      const _holdingfuBUXStringMinimized = stringNumberToStringFixed(_holdingfuBUXString, 4);
      setHoldingsStakedfuBUX(_holdingfuBUXStringMinimized);
    } else {
      setHoldingsStakedfuBUX("0");
    }
  }, [connectedWallet, fuBUXBalance, sharePrice]);

  useEffect(() => {
    if (connectedWallet && sharePrice > 0 && fBUX80USDCe20Balance > 0n) {
      const fuBUXBalanceDecimal = bigintToFormattedDecimal(fBUX80USDCe20Balance);
      const _holdingfuBUX = fuBUXBalanceDecimal.mul(sharePrice);
      const _holdingfuBUXString = _holdingfuBUX.toFixed();
      const _holdingfuBUXStringMinimized = stringNumberToStringFixed(_holdingfuBUXString, 4);
      setHoldingsUnstaked80fBUX20USDCe(_holdingfuBUXStringMinimized);
    } else {
      setHoldingsUnstaked80fBUX20USDCe("0");
    }
  }, [connectedWallet, fBUX80USDCe20Balance, sharePrice]);

  useEffect(() => {
    setUnstakeNewBalance(fuBUXBalance);
  }, [fuBUXBalance]);

  useEffect(() => {
    setStakeNewBalance(fBUX80USDCe20Balance);
  }, [fBUX80USDCe20Balance]);

  useEffect(() => {
    if (connectedWallet) {
      getAllowance();

      async function getAllowance() {
        const allowance = await erc20Contract.allowance(connectedWallet.address, erc20Addresses.xGHST, erc20Addresses.fGHST80USDCe20);
        setAllowance(allowance);
      }
    }
  }, [connectedWallet, refreshAllowance]);

  useEffect(() => {
    fetchPoolTokens();

    async function fetchPoolTokens() {
      if (poolTokens && poolTokensTotalSupply) {
        const ownedStakedIndividualTokens = getPoolIndividualTokens(fuBUXBalance, poolTokensTotalSupply, poolTokens);
        setPoolStakedTokensIndividual(ownedStakedIndividualTokens);
      }
    }
  }, [poolTokens, fuBUXBalance, poolTokensTotalSupply]);

  useEffect(() => {
    fetchPoolTokens();

    async function fetchPoolTokens() {
      if (poolTokens && poolTokensTotalSupply) {
        const ownedUnstakedIndividualTokens = getPoolIndividualTokens(fBUX80USDCe20Balance, poolTokensTotalSupply, poolTokens);
        setPoolUnstakedTokensIndividual(ownedUnstakedIndividualTokens);
      }
    }
  }, [poolTokens, fBUX80USDCe20Balance, poolTokensTotalSupply]);

  useEffect(() => {
    if (stakingAction === StakingAction.Stake) {
      setStakeNewBalance(fBUX80USDCe20Balance);
    } else if (stakingAction === StakingAction.Withdraw) {
      setUnstakeNewBalance(fuBUXBalance);
    }
  }, [stakingAction]);

  function clearAccountFields() {
    setAllowance(0n);
    setfBUX80USDCe20Balance(0n);
    setfuBUXBalance(0n);

    setStakeClaimableRewards([]);
    setCommitWithdrawAmountBI(0n);
  }

  async function approvefBUX80USDCe20() {
    if (connectedWallet) {
      setApproveLoading(true);
      const signer = await browserProvider.getSigner();
      const tx = await approve(signer, erc20Addresses.fGHST80USDCe20, erc20Addresses.xGHST, commitAmountBI);
      if (tx) {
        setRefreshAllowance(!refreshAllowance);
      }
      setApproveLoading(false);
    }
  }

  async function claimRewards() {
    if (connectedWallet) {
      setClaimRewardsLoading(true);
      const signer = await browserProvider.getSigner();
      const tx = await stakeContract.getReward(signer);
      if (tx) {
        setRefreshRewards(!refreshRewards);
      }
      setClaimRewardsLoading(false);
    }
  }

  async function stakefBUX80USDCe20() {
    if (connectedWallet) {
      setStakeLoading(true);
      const signer = await browserProvider.getSigner();
      const tx = await stakeContract.deposit(signer, commitAmountBI);
      if (tx) {
        setRefreshAllowance(!refreshAllowance);
        setRefreshBalances(!refreshBalances);
        setCommitAmountBI(0n);
      }
      setStakeLoading(false);
    }
  }

  async function withdrawfBUX80USDCe20() {
    if (connectedWallet) {
      setStakeLoading(true);
      const signer = await browserProvider.getSigner();
      const tx = await stakeContract.withdraw(signer, commitWithdrawAmountBI);
      if (tx) {
        setRefreshBalances(!refreshBalances);
        setCommitAmountBI(0n);
      }
      setStakeLoading(false);
    }
  }

  function stakedSliderChanged(percentageToRemove: number): void {
    const fBUX80USDCe20ToRemove = new Decimal(fBUX80USDCe20Balance.toString()).mul(percentageToRemove).div(100).toFixed(0);
    setStakeNewBalance(BigInt(fBUX80USDCe20ToRemove));
  }

  function unstakedSliderChanged(percentageToRemove: number): void {
    const fuBUXToRemove = new Decimal(fuBUXBalance.toString()).mul(percentageToRemove).div(100).toFixed(0);
    setUnstakeNewBalance(BigInt(fuBUXToRemove));
  }

  function getStakingActionLayout() {
    if (stakingAction === StakingAction.Stake) {
      return getStakeActionLayout();
    } else if (stakingAction === StakingAction.Withdraw) {
      return getWithdrawActionLayout();
    }
  }

  function setStakeNewBalance(newfBUX80USDCe20Balance: bigint, updateHtmlInput = true) {
    setCommitAmountBI(newfBUX80USDCe20Balance);
    if (updateHtmlInput) {
      const htmlInputElement = document.getElementById("stake-commit-amount") as HTMLInputElement;
      if (htmlInputElement) {
        htmlInputElement.focus();
        htmlInputElement.value = ethers.formatEther(newfBUX80USDCe20Balance.toString());
      }
    } else {
      // TODO: Should move bar correctly
    }
  }

  function setUnstakeNewBalance(newfuBUXBalance: bigint, updateHtmlInput = true) {
    setCommitWithdrawAmountBI(newfuBUXBalance);
    if (updateHtmlInput) {
      const htmlInputElement = document.getElementById("unstake-commit-amount") as HTMLInputElement;
      if (htmlInputElement) {
        htmlInputElement.focus();
        htmlInputElement.value = ethers.formatEther(newfuBUXBalance.toString());
      }
    } else {
      // TODO: Should move bar correctly
    }
  }

  function getStakeActionLayout() {
    function onCommitAmountKeyUp(e: any): void {
      setStakeNewBalance(ethers.parseEther(e.target.value || "0"), false);
      setRefreshAllowance(!refreshAllowance);
    }

    return (
      <>
        <div className="staking-action-title">
          <div className="staking-subtitle">Unstaked Balance</div>
          <div className="staking-individual-tokens-wrapper">
            <div className="staking-tooltip" id="staking-individual-tokens-div">
              <div className="staking-token">{fBUX80USDCe20Balance ? bigintToFixed(fBUX80USDCe20Balance, 18, 6) : "0.0"}</div>
              <div className="staking-token-text"> 80fGhost-20sFTMx </div>
            </div>
            <Tooltip anchorSelect="#staking-individual-tokens-div" opacity={0.97}>
              <div className="staking-individual-tokens">
                <div>
                  <div className="staking-token">
                    {poolUnstakedTokensIndividual && poolUnstakedTokensIndividual.fBUX
                      ? bigintToFixed(poolUnstakedTokensIndividual.fBUX, 18, 2)
                      : "0.0"}
                  </div>
                  <img
                    src="/tokens/fantom/0x8b7007e1d02d8387b7b4bc8c6172598780ae59b2.svg"
                    alt="fGhost"
                    className="staking-token-image"
                  ></img>
                  <span>+</span>
                  <img src="/tokens/fantom/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83.svg" alt="WFTM" className="staking-token-image"></img>
                  <div className="staking-token">
                    {poolUnstakedTokensIndividual && poolUnstakedTokensIndividual.USDCe
                      ? bigintToFixed(poolUnstakedTokensIndividual.USDCe, 6, 2)
                      : "0.0"}
                  </div>
                </div>
              </div>
            </Tooltip>
            <div className="staking-text-usd">${holdingsUnstaked80fBUX20USDCe}</div>
          </div>
        </div>
        <div className="staking-action-input">
          <div
            className="staking-action-input-wrapper"
            onClick={() => {
              document.getElementById("stake-commit-amount")?.focus();
            }}
          >
            <div className="staking-commit-amount">
              <input type="number" id="stake-commit-amount" placeholder="0" onKeyUp={(e) => onCommitAmountKeyUp(e)} />
            </div>
          </div>
        </div>
        <ReactSlider
          disabled={fBUX80USDCe20Balance === 0n}
          className="borrow-slider"
          marks={10}
          min={0}
          max={100}
          step={1}
          thumbClassName={`borrow-slider-thumb ${fBUX80USDCe20Balance === 0n ? "disabled" : ""}`}
          markClassName="borrow-slider-mark"
          trackClassName="borrow-slider-track"
          onAfterChange={(value) => stakedSliderChanged(value)}
          defaultValue={100}
        />
        <div className="staking-action-commit">
          {allowance === 0n || allowance < commitAmountBI ? (
            <button
              className="selected regular-btn"
              onClick={approvefBUX80USDCe20}
              disabled={commitAmountBI === 0n || commitAmountBI > fBUX80USDCe20Balance}
            >
              {!approveLoading ? (
                <>Approve</>
              ) : (
                <div className="hashloader-wrapper">
                  <HashLoader color="#181f1b" size={15} loading={approveLoading} />
                </div>
              )}
            </button>
          ) : (
            <LoadingButtonComponent
              onClick={stakefBUX80USDCe20}
              disabled={commitAmountBI === 0n || commitAmountBI > fBUX80USDCe20Balance}
              isLoading={stakeLoading}
              content={"Stake"}
            />
          )}
        </div>
        <div className="staking-action-disclaimer">
          <div className="staking-gray-text">
            To stake you need to create (or add liquidity) 80fGhost-20sFTMx LP on{" "}
            <a target="_blank" href={beetsPoolAddress}>
              BeethovenX
            </a>
          </div>
          <div className="staking-gray-text">
            Make sure to untick "Zap into Farm" while creating LP otherwise you'll have to unstake from BeethovenX to deposit
          </div>
        </div>
      </>
    );
  }

  function getWithdrawActionLayout() {
    function onCommitAmountKeyUp(e: any): void {
      setUnstakeNewBalance(ethers.parseEther(e.target.value || "0"), false);
      setRefreshAllowance(!refreshAllowance);
    }

    return (
      <>
        <div className="staking-action-title">
          <div className="staking-subtitle">Withdraw Amount</div>
          <div className="staking-individual-tokens-wrapper">
            <div className="staking-tooltip" id="staking-staked-individual-tokens-div">
              <div className="staking-token">{commitWithdrawAmountBI ? bigintToFixed(commitWithdrawAmountBI, 18, 6) : "0.0"}</div>
              <div
                className="staking-token-text"
                onClick={() => window.open("https://ftmscan.com/token/0x6B0B150A7a37b1E592F553E2b7D71d6D1439dc57", "_blank")}
              >
                xGHST
              </div>
            </div>
            <Tooltip anchorSelect="#staking-staked-individual-tokens-div" opacity={0.97}>
              <div className="staking-individual-tokens">
                <div>
                  <div className="staking-token">
                    {poolStakedTokensIndividual && poolStakedTokensIndividual.fBUX
                      ? bigintToFixed(poolStakedTokensIndividual.fBUX, 18, 2)
                      : "0.0"}
                  </div>
                  <img
                    src="/tokens/fantom/0x8b7007e1d02d8387b7b4bc8c6172598780ae59b2.png"
                    alt="fGhost"
                    className="staking-token-image"
                  ></img>
                  <span>+</span>
                  <img
                    src="/tokens/fantom/0x2f733095b80a04b38b0d10cc884524a3d09b836a.svg"
                    alt="sFTMx"
                    className="staking-token-image"
                  ></img>
                  <div className="staking-token">
                    {poolStakedTokensIndividual && poolStakedTokensIndividual.USDCe
                      ? bigintToFixed(poolStakedTokensIndividual.USDCe, 6, 2)
                      : "0.0"}
                  </div>
                </div>
              </div>
            </Tooltip>
            <div className="staking-text-usd">${holdingsStakedfuBUX}</div>
          </div>
        </div>
        <div className="staking-action-input">
          <div
            className="staking-action-input-wrapper"
            onClick={() => {
              document.getElementById("unstake-commit-amount")?.focus();
            }}
          >
            <div className="staking-commit-amount">
              <input type="number" id="unstake-commit-amount" placeholder="0" onKeyUp={(e) => onCommitAmountKeyUp(e)} />
            </div>
          </div>
        </div>
        <ReactSlider
          disabled={fuBUXBalance === 0n}
          className="borrow-slider"
          marks={10}
          min={0}
          max={100}
          step={1}
          thumbClassName={`borrow-slider-thumb ${fuBUXBalance === 0n ? "disabled" : ""}`}
          markClassName="borrow-slider-mark"
          trackClassName="borrow-slider-track"
          onAfterChange={(value) => unstakedSliderChanged(value)}
          defaultValue={100}
        />
        <div className="staking-action-commit">
          <button className="regular-btn" disabled={commitWithdrawAmountBI === 0n} onClick={withdrawfBUX80USDCe20}>
            {!stakeLoading ? (
              <>Withdraw</>
            ) : (
              <div className="hashloader-wrapper">
                <HashLoader color="#181f1b" size={15} loading={stakeLoading} />
              </div>
            )}
          </button>
        </div>
        <div className="staking-action-disclaimer">
          <div className="staking-gray-text">
            To unstake you need to remove your 80fGhost-20sFTMx LP on{" "}
            <a target="_blank" href={beetsPoolAddress}>
              BeethovenX
            </a>
          </div>
        </div>
      </>
    );
  }

  function haveRewards() {
    return stakeClaimableRewards.length && stakeClaimableRewards.find((scr) => scr.claimable !== 0n) !== undefined;
  }

  return (
    <div className="staking page-container">
      <div className="staking-wrapper">
        <div className="staking-summary">
          <div className="staking-balance white-card">
            <img src="fghost-sftmx.png" style={{ height: "40px" }} />
            <div className="staking-balance-text">fGHST/sFTMx</div>
          </div>
          {!haveRewards() || (haveRewards() && stakingAction !== StakingAction.Withdraw) ? (
            <div className="staking-ghost">
              <img src="fghost.png" style={{ height: "300px" }} />
            </div>
          ) : null}
        </div>
        <div className="staking-stake-wrapper">
          <div className="staking-tvl white-card">
            <div className="staking-tvl-text">TVL</div>
            <div className="staking-tvl-amount">${totalTVLDisplay}</div>
          </div>
          <div className="staking-stake white-card">
            <div className="staking-balances">
              <div className="staking-balances-info">
                <div className="staking-balance-summary">
                  <div className="staking-summary-text">Staked Balance</div>
                  <div className="staking-individual-tokens">
                    <div id="unstaking-individual-tokens-div">
                      <div className="staking-token">
                        {poolStakedTokensIndividual.fBUX ? bigintToFixed(poolStakedTokensIndividual.fBUX, 18, 2) : "0.0"}
                      </div>
                      <img
                        src="/tokens/fantom/0x8b7007e1d02d8387b7b4bc8c6172598780ae59b2.png"
                        alt="fGhost"
                        className="staking-token-image"
                      ></img>
                      <span>+</span>
                      <img
                        src="/tokens/fantom/0xd7028092c830b5c8fce061af2e593413ebbc1fc1.svg"
                        alt="sFTMx"
                        className="staking-token-image"
                      ></img>
                      <div className="staking-token">
                        {poolStakedTokensIndividual.USDCe ? bigintToFixed(poolStakedTokensIndividual.USDCe, 6, 2) : "0.0"}
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <Tooltip anchorSelect="#unstaking-individual-tokens-div" opacity={0.97}>
                    <div className="staking-tooltip">
                      <div className="staking-token">{fuBUXBalance ? bigintToFixed(fuBUXBalance, 18, 6) : "0.0"}</div>
                      <div className="staking-token-text">80fGHST-20sFTMx</div>
                    </div>
                  </Tooltip>
                  <div className="staking-text-usd">${holdingsStakedfuBUX}</div>
                </div>
              </div>
              <div className="staking-staked-summary-apr">
                <div className="staking-summary-text">Staking APR</div>
                <div className="staking-sapr-value">
                  <div id="reward-tooltip">
                    {stakingAPR?.toFixed(2)}% <img style={{ whiteSpace: "pre-line" }} data-html="true" src="info-red.svg" />
                    <Tooltip anchorSelect="#reward-tooltip" opacity={0.97}>
                      <div className="tooltip-container">
                        <div>
                          <div className="tooltip-reward-desc-fees">Fees</div> APR:
                          <div className="tooltip-text">{feesAPR?.toFixed(2)}%</div>
                        </div>
                        {stakeRewardsAprs.map((stakeRewardApr) => {
                          return (
                            <div key={stakeRewardApr.address}>
                              <div className="tooltip-reward-desc">
                                <img
                                  src={`./tokens/fantom/${stakeRewardApr.address.toLowerCase()}.svg`}
                                  alt={stakeRewardApr.address}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "question.png";
                                  }}
                                />
                              </div>
                              APR:
                              <div className="tooltip-text">{stakeRewardApr.apr.toFixed(2)}%</div>
                            </div>
                          );
                        })}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="staking-action-buttons">
              <button
                className={`staking-stake-btn regular-btn ${stakingAction === StakingAction.Stake ? "selected" : "unselected"}`}
                onClick={() => setStakingAction(StakingAction.Stake)}
              >
                Stake
              </button>
              <button
                className={`staking-withdraw-btn regular-btn ${stakingAction === StakingAction.Withdraw ? "selected" : "unselected"}`}
                onClick={() => setStakingAction(StakingAction.Withdraw)}
              >
                Withdraw
              </button>
            </div>
            {getStakingActionLayout()}
          </div>

          <div className="white-card staking-rewards">
            {!haveRewards() ? (
              <div className="top-market no-assets">No assets to claim yet.</div>
            ) : (
              <>
                <div className="table-header">
                  <div className="table-cell table-cell-increase">Claimable Rewards</div>
                  <div className="table-cell min-width-95 balance-cell">Amount</div>
                  <div className="table-cell min-width-70 balance-cell">USD</div>
                </div>
                {stakeClaimableRewards.map((stakeClaimableReward) => {
                  return (
                    <div className="top-market table-row" key={stakeClaimableReward.address}>
                      <div className="table-cell table-cell-increase">
                        <div className="image-wrapper">
                          <img
                            src={`./tokens/fantom/${stakeClaimableReward.address.toLowerCase()}.svg`}
                            alt={stakeClaimableReward.address}
                          />
                        </div>
                        <div className="top-market-inner-text">{stakeClaimableReward.symbol}</div>
                      </div>
                      <div className="table-cell min-width-95 balance-cell">
                        <div className="top-market-inner-text">{bigintToFixed(stakeClaimableReward.claimable, 18, 4)}</div>
                      </div>
                      <div className="table-cell min-width-70 balance-cell">
                        ${stringNumberToStringFixed(stakeClaimableReward.holdings, 2)}
                      </div>
                    </div>
                  );
                })}
                <div className="staking-buttons">
                  {haveRewards() ? (
                    <LoadingButtonComponent onClick={() => claimRewards()} isLoading={claimRewardsLoading} content={"Claim Rewards"} />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
