import { ethers } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";
import eStrategy from "../../assets/abi/eStrategy.json";
import { USE_MULTICALL, globalProvider } from "../blockchain";

function getContract(strategyAddress: string, isMulticall: boolean, provider: ethers.JsonRpcProvider) {
  const _provider = provider ? provider : globalProvider;
  const _multicallGlobalProvider = MulticallWrapper.wrap(_provider);
  return new ethers.Contract(strategyAddress, eStrategy.abi, isMulticall && USE_MULTICALL ? _multicallGlobalProvider : _provider);
}

// Read

export async function _totalAssets(provider: ethers.JsonRpcProvider, strategyAddress: string, lpAddress: string): Promise<bigint> {
  return getContract(strategyAddress, false, provider)._totalAssets(lpAddress);
}

// Bulk

export function _totalAssets_bulk(provider: ethers.JsonRpcProvider, strategyAddresses: string[], lpAddresses: string[]): Promise<bigint>[] {
  return lpAddresses.map((_, index) => _totalAssets(provider, strategyAddresses[index], lpAddresses[index]));
}