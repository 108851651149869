export interface Root {
    success: string;
    length: number;
    data: IEQData;
}

export interface IEQData {
    [address: string]: IEQPairData;
}

export interface IEQPairData {
    id: number;
    name: string;
    symbol: string;
    displayName: string;
    displayType: string;
    extendedType: any;
    address: string;
    chainId: number;
    decimals: number;
    isStable: boolean;
    apr: string;
    aprLastEpoch: string;
    aprIncoming: string;
    totalSupply: string;
    tvlUsd: string;
    tags: string[];
    token0: IEQToken;
    token1: IEQToken;
    lastActiveTimestamp: number;
    tvl: string;
}

export interface IEQToken {
    symbol: string;
    address: string;
    decimals: number;
}

const API_URL = 'https://eqapi-fantom-prod-7bbji.ondigitalocean.app/fantom/v4/pairs';
export async function fetchFantomPairsData(): Promise<IEQData> {
    try {
        const response = await fetch(API_URL);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: Root = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching Fantom pairs data:', error);
        throw error;
    }
}