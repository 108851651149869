import { Web3OnboardProvider } from "@web3-onboard/react";
import "dotenv/config";
import { useEffect, useState } from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import DisclaimerComponent from "./components/disclaimer";
import LayoutPage from "./layout/layout";
import CrematoriumPage from "./pages/crematorium";
import FurnacePage from "./pages/furnace";
import { initWeb3Onboard } from "./services";

export default function App() {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  useEffect(() => {
    const disclaimerSaved = JSON.parse(localStorage.getItem("disclaimer") as string);
    if (disclaimerSaved) {
      const dateString = Number(disclaimerSaved.timestamp);
      const now = new Date().getTime();
      if (now - dateString > 2629746000) {
        const object = { value: "true", timestamp: new Date().getTime() };
        localStorage.setItem("disclaimer", JSON.stringify(object));
        setShowDisclaimer(true);
      }
    } else {
      const object = { value: "true", timestamp: new Date().getTime() };
      localStorage.setItem("disclaimer", JSON.stringify(object));
      setShowDisclaimer(true);
    }
  }, []);

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <LayoutPage />,
      children: [
        { index: true, element: <Navigate to="/furnace" replace /> },
        { path: "/furnace", element: <FurnacePage /> },
        { path: "/crematorium", element: <CrematoriumPage /> },
      ],
    },
  ];

  const savedTheme = localStorage.getItem("theme");
  let defaultTheme = "dark";
  if (savedTheme) {
    if (!JSON.parse(savedTheme)) {
      defaultTheme = "light";
    }
  }

  const element = useRoutes(routes);
  const themes = {
    light: "css/light.css",
    dark: "css/dark.css",
  };

  return (
    <ThemeSwitcherProvider defaultTheme={defaultTheme} themeMap={themes}>
      <Web3OnboardProvider web3Onboard={initWeb3Onboard}>
        <div className="app">{element}</div>
        {showDisclaimer && <DisclaimerComponent closeFn={() => setShowDisclaimer(false)} />}
        <ToastContainer theme={defaultTheme} />
      </Web3OnboardProvider>
    </ThemeSwitcherProvider>
  );
}
