import { useWallets } from "@web3-onboard/react";
import Decimal from "decimal.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import LoadingButtonComponent from "../components/loading-button";
import { TokenPrice } from "../entities/beets-graphql.entities";
import { IPoolInfo, IUserInfo } from "../entities/interfaces";
import { getBeetsPools, getBeetsTokenPrices } from "../helpers/beets-graphql";
import { erc20Addresses } from "../helpers/blockchain";
import * as crematoriumLPContract from "../helpers/contracts/crematoriumLP.contract";
import * as erc20Contract from "../helpers/contracts/erc20.contract";
import * as eStrategyContract from "../helpers/contracts/eStrategy.contract";
import * as ghostChefContract from "../helpers/contracts/ghostChef.contract";
import * as ghostLensContract from "../helpers/contracts/ghostLens.contract";
import { fetchFantomPairsData } from "../helpers/equalizer";
import "./crematorium.scss";

interface ICrematoriumLPInfo {
  address: string;
  symbol?: string;
  description?: string;
  tokens?: string[];
}

const crematoriumLPInfoOverwrites: ICrematoriumLPInfo[] = [];
// [
//   {
//     address: "0xFb586f8AcabcFb1e406b6dd67a6BFd29511346E5",
//     symbol: "Test symbol",
//     description: "Test description",
//     tokens: ["0x8b7007e1d02d8387b7b4bc8c6172598780ae59b2", "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"],
//   },
// ];

export default function CrematoriumPage() {
  const [connectedWallet, setConnectedWallet] = useState<any>(null);
  const [browserProvider, setBrowserProvider] = useState<any>(null);

  // Update control
  const [withdrawLPLoading, setWithdrawLPLoading] = useState<boolean[]>([]);
  const [depositLPLoading, setDepositLPLoading] = useState<boolean[]>([]);
  const [approveLPLoading, setApproveLPLoading] = useState<boolean[]>([]);
  const [claimRewardsLoading, setClaimRewardsLoading] = useState<boolean[]>([]);
  const [poolAddresses, setPoolAddresses] = useState<string[]>([]);
  const [poolRewards, setPoolRewards] = useState<bigint[]>([]);
  const [poolInfos, setPoolInfos] = useState<IPoolInfo[]>([]);
  const [userInfos, setUserInfos] = useState<IUserInfo[]>([]);
  const [totalLPAssets, setTotalLPAssets] = useState<bigint[]>([]);
  const [poolIds, setPoolIds] = useState<number[]>([]);
  const [allLPInfo, setAllLPInfo] = useState<ICrematoriumLPInfo[]>([]);
  const [depositInputDisplay, setDepositInputAmounts] = useState<string[]>([]);
  const [withdrawInputDisplay, setWithdrawInputAmounts] = useState<string[]>([]);
  const [walletBalances, setWalletBalances] = useState<bigint[]>([]);
  const [tvls, setTVLs] = useState<bigint[]>([]);
  const [aprsYear, setAPRsYear] = useState<number[]>([]);
  const [aprsWeek, setAPRsWeek] = useState<number[]>([]);
  const [aprsDay, setAPRsDay] = useState<number[]>([]);

  // At init
  const [tokenPrices, setTokenPrices] = useState<TokenPrice[]>([]);
  const [allowances, setAllowances] = useState<bigint[]>([]);
  const [rSIXPerSecond, setrSIXPerSecond] = useState<bigint>(0n);
  const [poolEmissionsUSD, setPoolEmissionsUSD] = useState<bigint[]>([]);

  // Update control
  const [refreshAllowances, setRefreshAllowances] = useState<boolean>(false);
  const [refreshBalances, setRefreshBalances] = useState<boolean>(false);

  const wallets = useWallets();

  useEffect(() => {
    loadLPAddresses();
    updateTokenPrices();
    loadrSIXPerSecond();

    async function loadLPAddresses() {
      const poolAddresses = await ghostChefContract.readPoolList();
      setPoolAddresses(poolAddresses);
    }

    async function updateTokenPrices() {
      const tokenPricesResponse = await getBeetsTokenPrices();
      const tokenPricesData = tokenPricesResponse.data;
      setTokenPrices(tokenPricesData.tokenPrices);
    }

    async function loadrSIXPerSecond() {
      const rSIXPerSecondResponse = await ghostChefContract.rSIXPerSecond();
      setrSIXPerSecond(rSIXPerSecondResponse);
    }
  }, []);

  useEffect(() => {
    loadInitValues();
    loadPoolIds();
    loadPoolPrices();

    async function loadInitValues() {
      if (poolAddresses.length) {
        const groupedPromises: Promise<string | string[]>[] = [];

        for (let i = 0; i < poolAddresses.length; i++) {
          const tokenAddress = poolAddresses[i];

          groupedPromises.push(crematoriumLPContract.getSymbol(tokenAddress));
          groupedPromises.push(crematoriumLPContract.getName(tokenAddress));
          groupedPromises.push(crematoriumLPContract.getTokens(tokenAddress));
        }

        const groupedResults = await Promise.all(groupedPromises);

        let _allLPInfo = [];
        for (let i = 0; i < poolAddresses.length; i++) {
          const overwritedLP = crematoriumLPInfoOverwrites.find((lp) => lp.address === poolAddresses[i]);
          const baseLP: ICrematoriumLPInfo = { address: poolAddresses[i] };
          baseLP.symbol = groupedResults[i * 3] as string;
          baseLP.description = groupedResults[i * 3 + 1] as string;
          baseLP.tokens = groupedResults[i * 3 + 2] as string[];
          const _lpInfo = Object.assign({}, baseLP, overwritedLP);
          _allLPInfo.push(_lpInfo);
        }

        setWithdrawInputAmounts(poolAddresses.map((_) => "0"));
        setDepositInputAmounts(poolAddresses.map((_) => "0"));
        setAllLPInfo(_allLPInfo);
      }
    }

    async function loadPoolIds() {
      if (poolAddresses.length) {
        const poolIds = await Promise.all(ghostChefContract.poolId_bulk(poolAddresses, browserProvider));
        setPoolIds(poolIds);
      }
    }

    async function loadPoolPrices() {
      if (poolAddresses.length) {
        getBeetsPools();
      }
    }
  }, [poolAddresses]);

  useEffect(() => {
    if (wallets.length) {
      setBrowserProvider(new ethers.BrowserProvider(wallets[0].provider));
      setConnectedWallet(wallets[0].accounts[0]);
    } else {
      setConnectedWallet(null);
    }
  }, [wallets]);

  useEffect(() => {
    getAllowances();

    async function getAllowances() {
      if (connectedWallet && poolAddresses.length) {
        const _allowances = await Promise.all(
          erc20Contract.allowance_bulk(connectedWallet.address, erc20Addresses.ghostChef, poolAddresses, browserProvider),
        );
        setAllowances(_allowances);
      }
    }
  }, [connectedWallet, refreshAllowances, poolAddresses]);

  useEffect(() => {
    loadLPBalances();

    async function loadLPBalances() {
      if (connectedWallet && poolAddresses.length) {
        const _walletBalances = await Promise.all(erc20Contract.balanceOf_bulk(connectedWallet.address, poolAddresses, browserProvider));
        setWalletBalances(_walletBalances);
      }
    }
  }, [connectedWallet, refreshBalances, poolAddresses]);

  useEffect(() => {
    loadPoolInfos();
    loadUserInfos();

    async function loadPoolInfos() {
      if (connectedWallet && poolIds.length) {
        const _poolInfos = await Promise.all(ghostChefContract.poolInfo_bulk(poolIds, browserProvider));
        setPoolInfos(_poolInfos);
      }
    }

    async function loadUserInfos() {
      if (connectedWallet && poolIds.length) {
        const _userInfos = await Promise.all(ghostChefContract.userInfo_bulk(poolIds, connectedWallet.address, browserProvider));
        setUserInfos(_userInfos);
      }
    }
  }, [connectedWallet, refreshBalances, poolIds]);

  useEffect(() => {
    loadTotalAssets();
    loadTotalAssets();

    async function loadTotalAssets() {
      if (poolInfos.length) {
        const _totalAssets = await Promise.all(
          eStrategyContract._totalAssets_bulk(
            browserProvider,
            poolInfos.map((p) => p.strategy),
            poolInfos.map((p) => p.lpToken),
          ),
        );
        setTotalLPAssets(_totalAssets);
      }
    }
  }, [poolInfos]);

  useEffect(() => {
    calculateLPRewards();

    async function calculateLPRewards() {
      if (userInfos.length && poolInfos.length) {
        const pendingRewards = userInfos.map((userInfo, index) => {
          return (userInfo.amount * poolInfos[index].accFGHSTPerShare) / 10n ** 12n - userInfo.rewardDebt;
        });
        const pendingrSIX = await Promise.all(ghostChefContract.pendingRSIX_bulk(poolIds, connectedWallet.address, browserProvider));
        setPoolRewards(pendingrSIX);
        debugger;
      }
    }
  }, [userInfos, poolInfos]);

  useEffect(() => {
    loadAPYs();
    loadPoolTVLs();

    async function loadAPYs() {
      if (rSIXPerSecond && poolInfos.length && tokenPrices.length) {
        const rSIXPrice = tokenPrices.find((tp) => tp.address.toLowerCase() === erc20Addresses.fGHST.toLowerCase());
        if (rSIXPrice) {
          const poolEmissionsUSD = poolInfos.map(
            (poolInfo) => (rSIXPerSecond * 4000n * poolInfo.allocPoint * ethers.parseEther(rSIXPrice.price.toString())) / 10n ** 18n,
          );
          setPoolEmissionsUSD(poolEmissionsUSD);
        }
      }
    }

    async function loadPoolTVLs() {
      if (poolInfos.length && allLPInfo.length && totalLPAssets.length) {
        const whichStratResponses = await Promise.all(
          ghostLensContract.whichStrat_bulk(
            poolInfos.map((p) => p.lpToken),
            browserProvider,
          ),
        );
        const eqData = await fetchFantomPairsData();
        let poolTVLs = [];
        for (let i = 0; i < whichStratResponses.length; i++) {
          const whichStrat = whichStratResponses[i];
          if (whichStrat === "EQ") {
            const eqPoolData = eqData[poolAddresses[i]];
            const lpAssets = totalLPAssets[i];
            if (eqPoolData) {
              const eqTVL = new Decimal(eqPoolData.tvlUsd);
              const eqTotalSupply = new Decimal(eqPoolData.totalSupply);
              const poolTVL = eqTVL.div(eqTotalSupply).mul(ethers.formatEther(lpAssets));
              poolTVLs.push(ethers.parseEther(poolTVL.toFixed(2)));
            }
          } else if (whichStrat === "BEETS") {
            poolTVLs.push(0n);
          } else {
            poolTVLs.push(0n);
          }
        }
        setTVLs(poolTVLs);
      }
    }
  }, [poolInfos, rSIXPerSecond, tokenPrices, allLPInfo, totalLPAssets]);

  useEffect(() => {
    calculateAPYs();

    async function calculateAPYs() {
      if (tvls.length && poolEmissionsUSD.length) {
        const aprsYear = [];
        const aprsWeek = [];
        const aprsDay = [];
        for (let i = 0; i < tvls.length; i++) {
          const tvl = tvls[i];
          const poolEmissionUSD = poolEmissionsUSD[i];
          const fixedTVL = tvl === 0n ? 1n : tvl;
          const poolAPRYear = new Decimal(poolEmissionUSD.toString()).div(new Decimal(fixedTVL.toString())).mul(100).toFixed(2);
          const aprNumber = Number(poolAPRYear);
          aprsYear.push(aprNumber);
          const aprDay = Math.round((aprNumber / 365) * 100) / 100;
          aprsDay.push(aprDay);
          const aprWeek = Math.round(aprDay * 7 * 100) / 100;
          aprsWeek.push(aprWeek);
        }
        setAPRsYear(aprsYear);
        setAPRsWeek(aprsWeek);
        setAPRsDay(aprsDay);
      }
    }
  }, [tvls, poolEmissionsUSD]);

  function setMaxBalanceInWallet(index: number) {
    if (walletBalances[index] === 0n) return;

    setDepositInput(ethers.formatEther(walletBalances[index]), index);
  }

  function setMaxBalanceDeposited(index: number) {
    if (userInfos[index].amount === 0n) return;

    setWithdrawInput(ethers.formatEther(userInfos[index].amount), index);
  }

  function setDepositInput(value: string, index: number) {
    const _dInputAmounts = [...depositInputDisplay];
    _dInputAmounts[index] = value;
    setDepositInputAmounts(_dInputAmounts);
  }

  function setWithdrawInput(value: string, index: number) {
    const _wInputAmounts = [...withdrawInputDisplay];
    _wInputAmounts[index] = value;
    setWithdrawInputAmounts(_wInputAmounts);
  }

  async function withdrawLP(lpId: number, index: number) {
    const signer = await browserProvider.getSigner();
    const tx = await ghostChefContract.withdraw(signer, lpId, ethers.parseUnits(withdrawInputDisplay[index]));
    if (tx) {
      clearFields();
      setRefreshBalances(!refreshBalances);
    }
  }

  async function depositLP(lpId: number, index: number) {
    const signer = await browserProvider.getSigner();
    const tx = await ghostChefContract.deposit(signer, lpId, ethers.parseUnits(depositInputDisplay[index]));
    if (tx) {
      clearFields();
      setRefreshBalances(!refreshBalances);
    }
  }

  async function approveLP(address: string, index: number) {
    const signer = await browserProvider.getSigner();
    const tx = await erc20Contract.approve(signer, address, erc20Addresses.ghostChef, walletBalances[index]);
  }

  async function claimRewards() {
    const signer = await browserProvider.getSigner();
    const tx = await ghostChefContract.harvestAll(signer);
    if (tx) {
      clearFields();
      setRefreshBalances(!refreshBalances);
    }
  }

  function clearFields() {
    setWithdrawInputAmounts(poolAddresses.map((_) => "0"));
    setDepositInputAmounts(poolAddresses.map((_) => "0"));
  }

  return (
    <div className="page-container crematorium">
      <div className="crematorium-content">
        <div className="crematorium-vaults">
          {allLPInfo?.map((lpInfo, index) => {
            return (
              <div className="crematorium-vault-container" key={index}>
                <div className="crematorium-vault-amount" style={{ visibility: index === 0 ? "visible" : "hidden" }}>
                  {allLPInfo?.length} Vault{allLPInfo && allLPInfo.length > 1 ? "s" : ""}
                </div>
                <div className="crematorium-vault">
                  <div className="crematorium-vault-header">
                    <div className="crematorium-vault-tokens-img">
                      <img
                        src={`./tokens/fantom/${lpInfo.tokens ? lpInfo.tokens[0]?.toLowerCase() : ""}.svg`}
                        alt={lpInfo.tokens ? lpInfo.tokens[0] : ""}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "question.png";
                        }}
                      />
                      <img
                        src={`./tokens/fantom/${lpInfo.tokens ? lpInfo.tokens[1]?.toLowerCase() : ""}.svg`}
                        alt={lpInfo.tokens ? lpInfo.tokens[1] : ""}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "question.png";
                        }}
                      />
                    </div>
                    <div className="crematorium-vault-tokens-name">
                      <div className="crematorium-vault-tokens-name-text">{lpInfo.symbol}</div>
                      <div className="crematorium-vault-tokens-name-desc">{lpInfo.description}</div>
                    </div>
                    <div className="crematorium-vault-tokens-decor"></div>
                  </div>
                  <div className="crematorium-vault-tvl">
                    <div className="crematorium-vault-tvl-text">TVL</div>
                    <div className="crematorium-vault-tvl-value">${tvls.length ? ethers.formatEther(tvls[index]) : 0}</div>
                  </div>
                  <div className="crematorium-vault-tokens-total">
                    <div className="crematorium-vault-tokens-total-text">Total Tokens</div>
                    <div className="crematorium-vault-tokens-total-value">{ethers.formatEther(totalLPAssets[index] || 0n)}</div>
                  </div>
                  <div className="crematorium-vault-apy">
                    <div className="crematorium-vault-apy-day">
                      <div className="crematorium-vault-apy-day-text">Day</div>
                      <div className="crematorium-vault-apy-day-value">
                        {aprsDay[index] ? (aprsDay[index] > 10000000 ? aprsDay[index].toPrecision(2) : aprsDay[index]) : 0}%
                      </div>
                    </div>
                    <div className="crematorium-vault-apy-month">
                      <div className="crematorium-vault-apy-month-text">Week</div>
                      <div className="crematorium-vault-apy-month-value">
                        {aprsWeek[index] ? (aprsWeek[index] > 10000000 ? aprsWeek[index].toPrecision(2) : aprsWeek[index]) : 0}%
                      </div>
                    </div>
                    <div className="crematorium-vault-apy-year">
                      <div className="crematorium-vault-apy-year-text">Year</div>
                      <div className="crematorium-vault-apy-year-value">
                        {aprsYear[index] ? (aprsYear[index] > 10000000 ? aprsYear[index].toPrecision(2) : aprsYear[index]) : 0}%
                      </div>
                    </div>
                  </div>
                  <div className="crematorium-vault-input">
                    <div className="crematorium-vault-input-text">
                      <span>In wallet</span>
                      <span onClick={() => setMaxBalanceInWallet(index)}>
                        {ethers.formatUnits(walletBalances[index] || 0n, 18)}
                        <img
                          style={{ width: "15px" }}
                          src={`./tokens/fantom/${lpInfo.address}.svg`}
                          alt={lpInfo.address ? lpInfo.address : ""}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "question.png";
                          }}
                        />
                      </span>
                    </div>
                    <div className="crematorium-vault-input-field">
                      <input
                        type="number"
                        className="crematorium-vault-buttons-withdraw-input"
                        value={depositInputDisplay[index]}
                        onChange={(e) => setDepositInput(e.target.value, index)}
                        disabled={walletBalances[index] === 0n}
                      />
                      {walletBalances[index] > 0n &&
                      (allowances[index] === 0n || allowances[index] < ethers.parseUnits(depositInputDisplay[index])) ? (
                        <LoadingButtonComponent
                          cssClass="regular-btn selected-deposit"
                          onClick={() => approveLP(lpInfo.address, index)}
                          isLoading={approveLPLoading[index]}
                          content={"Approve"}
                        />
                      ) : (
                        <LoadingButtonComponent
                          cssClass="regular-btn selected-deposit"
                          onClick={() => depositLP(poolIds[index], index)}
                          isLoading={depositLPLoading[index]}
                          content={"Deposit"}
                          disabled={walletBalances[index] === 0n}
                        />
                      )}
                    </div>
                  </div>
                  <div className="crematorium-vault-input">
                    <div className="crematorium-vault-input-text">
                      <span>Deposited</span>
                      <span onClick={() => setMaxBalanceDeposited(index)}>
                        {ethers.formatUnits((userInfos.length ? userInfos[index].amount : 0n) || 0n, 18)}
                        <img
                          style={{ width: "15px" }}
                          src={`./tokens/fantom/${lpInfo.address}.svg`}
                          alt={lpInfo.address ? lpInfo.address : ""}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "question.png";
                          }}
                        />
                      </span>
                    </div>
                    <div className="crematorium-vault-input-field">
                      <input
                        type="number"
                        className="crematorium-vault-buttons-withdraw-input"
                        value={withdrawInputDisplay[index]}
                        onChange={(e) => setWithdrawInput(e.target.value, index)}
                        disabled={walletBalances[index] === 0n}
                      />
                      <LoadingButtonComponent
                        cssClass="regular-btn selected-withdraw"
                        onClick={() => withdrawLP(poolIds[index], index)}
                        isLoading={withdrawLPLoading[index]}
                        content={"Withdraw"}
                        disabled={(userInfos.length ? userInfos[index].amount : 0n) === 0n}
                      />
                    </div>
                  </div>
                  <div className="crematorium-vault-claim" style={{ display: !poolRewards[index] ? "none" : "flex" }}>
                    <div className="crematorium-vault-claim-text">Claim Rewards</div>
                    <div className="crematorium-vault-claim-field">
                      <div className="crematorium-vault-claim-img">
                        <img
                          src={`./tokens/fantom/0x5f6acD8EA6a74f33770106290A6FA3Fad7049CaA.svg`}
                          alt={lpInfo.tokens ? lpInfo.tokens[0] : ""}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "question.png";
                          }}
                        />
                      </div>
                      <div className="crematorium-vault-claim-value">{ethers.formatUnits(poolRewards[index] || 0)} rSIX</div>
                      <div className="crematorium-vault-buttons-deposit">
                        <LoadingButtonComponent
                          cssClass="regular-btn selected-deposit"
                          onClick={() => claimRewards()}
                          isLoading={claimRewardsLoading[index]}
                          content={"Claim"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}{" "}
        </div>
      </div>
    </div>
  );
}
