import { TransactionReceipt, ethers } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";
import { toast } from "react-toastify";
import { getAllTokensInfo } from "./dev-getAllMarkets";

export const USE_MULTICALL = false;
export const MAX_BIGINT = 115792089237316195423570985008687907853269984665640564039457584007913129639935n;
export const MARKET_DECIMALS = 8;

export const erc20Addresses = {
  // Tokens
  USDCe: "0x2f733095b80a04b38b0d10cc884524a3d09b836a",
  fGHST: "0x8b7007E1D02d8387B7B4BC8C6172598780ae59B2",
  xGHST: "0xFb586f8AcabcFb1e406b6dd67a6BFd29511346E5",
  rSIX: "0x3E9ADDD6932AA0de51F78c1ed3548Ae0b852fBEf",
  // Pools
  pool_Beethoven: "0xed42d5433a5532a2b24cecb70996b69305fd1b77000200000000000000000875",
  fGHST80USDCe20: "0xED42D5433a5532A2B24Cecb70996b69305fD1B77",
  vault_Beethoven: "0x20dd72ed959b6147912c2e529f0a0c651c33c9ce",
  ghostChef: "0xB0941fD160E0C4FA5d1a14F9600b0926A6f64b10",
};

export const fantomRPC = "https://rpc.ftm.tools/";
export const globalProvider = new ethers.JsonRpcProvider(fantomRPC);
export const multicallGlobalProvider = MulticallWrapper.wrap(globalProvider);
export const ALL_TOKENS_INFO = (await getAllTokensInfo()) || [];

export async function customSendTransaction(signer: ethers.JsonRpcSigner, unsignedTx: ethers.ContractTransaction): Promise<TransactionReceipt | null> {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;

  if (chainId !== 250n && window?.ethereum) {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0xfa" }],
    });
  }
  const txResponse = await signer.sendTransaction(unsignedTx).catch((error: any) => {
    let message = error?.shortMessage || error?.message || error;
    switch (error.data) {
      case "0x48c25881":
      case "0x91240a1b":
        message = "Insufficient liquidity on the market";
        break;
      case "0x480f42470000000000000000000000000000000000000000000000000000000000000004":
        message = "Can't withdraw more than your available";
        break;
    }
    toast.error(message, {
      position: "bottom-center",
    });
    return null;
  });
  if (txResponse) {
    return await txResponse.wait();
  }
  return null;
};
