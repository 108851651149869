import { ethers } from "ethers";
import vaultBeethoven from "../../assets/abi/VaultBeethoven.json";
import { USE_MULTICALL, erc20Addresses, globalProvider, multicallGlobalProvider } from "../blockchain";

function getContract(isMulticall = false) {
  return new ethers.Contract(
    erc20Addresses.vault_Beethoven,
    vaultBeethoven.abi,
    isMulticall && USE_MULTICALL ? multicallGlobalProvider : globalProvider,
  );
}

export interface IVaultBeethovenGetPoolTokens {
  tokens: string[];
  balances: bigint[];
  lastChangeBlock: bigint;
}

export async function getPoolTokens(poolId: string): Promise<IVaultBeethovenGetPoolTokens> {
  return getContract().getPoolTokens(poolId);
}
